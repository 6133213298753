import { useEffect, useState } from "react"

export function useWebPSupport() {
  let [webp, setWebp] = useState(true)

  useEffect(() => {
    setWebp(window.Modernizr.webp)
  }, [])

  return webp
}
