import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"

import PageLink from "../components/pageLink"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./directors.scss"
import {
  onBackToMain,
  onSetExitTo,
  BASE_TRANSITION_LENGTH,
} from "../transitions"
import { getVideoAssetUrl, onPreload } from "../assets"

import chrisVideo from "../videos/chrisKitten.mp4"
import chrisImage from "../images/chrisKitten.webp"
import chrisImageNoWebp from "../images/chrisKitten.png"
import chrisFrame from "../images/chrisFrame.webp"
import chrisFrameNoWebp from "../images/chrisFrame.png"

import anastasiaVideo from "../videos/anastasiaKitten.mp4"
import anastasiaImage from "../images/anastasiaKitten.webp"
import anastasiaImageNoWebp from "../images/anastasiaKitten.png"
import anastasiaFrame from "../images/anastasiaFrame.webp"
import anastasiaFrameNoWebp from "../images/anastasiaFrame.png"
import { useWebPSupport } from "../useWebpSupport"

const DirectorsPage = ({ transitionStatus }) => {
  const [preloaded, setPreloaded] = useState(false)
  const webp = useWebPSupport()
  const [pendingVideoPlays, setPendingVideoPlays] = useState([])
  const [pendingVideoClickPlays, setPendingVideoClickPlays] = useState([])

  useEffect(() => {
    let mounted = true
    onPreload(() => mounted && setPreloaded(true))
    return () => {
      mounted = false
    }
  }, [])

  function onVideoTriggerHover(director, el) {
    let video = el.parentElement.querySelector("video")
    video
      .play()
      .then(() => {
        let endPromise = new Promise(res => {
          video.addEventListener("ended", () => res())
        })
        setPendingVideoPlays(p => [...p, { director, promise: endPromise }])
      })
      .catch(e => {
        setPendingVideoClickPlays(p =>
          p.find(i => i.director === director) ? p : [...p, { director, video }]
        )
      })
  }

  function preVideoClick(director) {
    let pendingPlays = pendingVideoPlays.filter(i => i.director === director)
    if (pendingPlays.length > 0) {
      return Promise.all(pendingPlays.map(p => p.promise))
    }
    let pendingClickPlay = pendingVideoClickPlays.find(
      i => i.director === director
    )
    if (pendingClickPlay) {
      setPendingVideoClickPlays(
        pendingVideoClickPlays.filter(i => i.director !== director)
      )
      return new Promise(res => {
        pendingClickPlay.video.addEventListener("ended", () => res())
        pendingClickPlay.video.play().catch(() => res())
      })
    } else {
      return Promise.resolve(true)
    }
  }

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "page directors",
        }}
        key="helmet"
      />
      <Layout transitionStatus={transitionStatus} page="directors">
        <SEO title="Directors" />
        <div className="frames">
          <div className="director directorAnastasia">
            <PageLink
              to="/director/anastasia-kirillova"
              className="transitionLink transitionLink--director"
              exit={{
                length: BASE_TRANSITION_LENGTH,
                zIndex: 0,
                trigger: args => onSetExitTo("director", args),
              }}
              entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
              onPreClick={() => preVideoClick("anastasia")}
            >
              <img
                className="frame frameAnastasia"
                src={webp ? anastasiaFrame : anastasiaFrameNoWebp}
              />
              <div className="video videoAnastasia">
                <video
                  preload="auto"
                  width="380"
                  height="480"
                  poster={webp ? anastasiaImage : anastasiaImageNoWebp}
                  key={getVideoAssetUrl(anastasiaVideo)}
                  playsInline
                >
                  <source
                    src={getVideoAssetUrl(anastasiaVideo)}
                    type="video/mp4"
                  />
                </video>
                <div
                  className="videoTrigger"
                  onMouseOver={e => onVideoTriggerHover("anastasia", e.target)}
                ></div>
              </div>
            </PageLink>
            <div className="name nameAnastasia">Anastasia Kirillova</div>
          </div>
          <div className="director directorChris">
            <PageLink
              to="/director/chris-palmer"
              className="transitionLink transitionLink--director"
              exit={{
                length: BASE_TRANSITION_LENGTH,
                zIndex: 0,
                trigger: args => onSetExitTo("director", args),
              }}
              entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
              onPreClick={() => preVideoClick("chris")}
            >
              <img
                className="frame frameChris"
                src={webp ? chrisFrame : chrisFrameNoWebp}
              />
              <div className="video videoChris">
                <video
                  preload="auto"
                  width="403"
                  height="480"
                  poster={webp ? chrisImage : chrisImageNoWebp}
                  key={getVideoAssetUrl(chrisVideo)}
                  playsInline
                >
                  <source src={getVideoAssetUrl(chrisVideo)} type="video/mp4" />
                </video>
                <div
                  className="videoTrigger"
                  onMouseOver={e => onVideoTriggerHover("chris", e.target)}
                ></div>
              </div>
            </PageLink>
            <div className="name nameChris">Chris Palmer</div>
          </div>
        </div>
        <PageLink
          to="/"
          className="transitionLink transitionLink--home"
          exit={{
            length: BASE_TRANSITION_LENGTH,
            zIndex: 0,
            trigger: args => onSetExitTo("main", args),
          }}
          entry={{
            length: BASE_TRANSITION_LENGTH,
            zIndex: 10,
            trigger: args => onBackToMain("directors", args),
          }}
        ></PageLink>
        <PageLink
          to="/director/frank-budgen"
          className="transitionLink transitionLink--director transitionLink--directorFrank"
          exit={{
            length: BASE_TRANSITION_LENGTH,
            zIndex: 0,
            trigger: args => onSetExitTo("director", args),
          }}
          entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
        ></PageLink>
      </Layout>
    </>
  )
}

export default DirectorsPage
